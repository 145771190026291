:root {
    --agenda-radius: 0rem;
    --speaker-spacing: 2.5rem;
}

.agendaFilters {
    margin-top: var(--space-md);
}
.actions {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: .25rem;
}
@media (max-width:599px){
    .printButton {
        margin-top: var(--space-md);
    }
    .agendaFilters {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: var(--space-md);
    }
}
@media (min-width:600px){
    .agendaFilters {
        display: flex;
        justify-content: space-between;
    }
    .actions {
        justify-content: flex-end;
    }
}






.agenda, .day {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    gap: .5rem;
}

.dayDivider {
    background-color: var(--primary);
    border-radius: var(--agenda-radius);
    padding: .5rem 1rem;
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
}

.timeSlot {
    display: grid;
    grid-template-columns: 1fr;
}
.timingsAndTags {
    background-color: var(--secondary);
    border-radius: calc(var(--agenda-radius) * 1);
    padding: .5rem 1rem;
}

@media(max-width:500px){
    .timeSlot {
        border: 2px solid var(--secondary);
        border-radius: calc(var(--agenda-radius) * 1.25);
    }
    .timingsAndTags {
       border-bottom-right-radius: 0;
       border-bottom-left-radius: 0;
    }
    .sessions {
        padding: .25rem;
    }
}
@media(min-width:600px){
    .timeSlot {
        grid-template-columns: 9rem auto;
        gap: .5rem;
    }
    .timingsAndTags {
        padding: 1rem;
    }
}

.sessions {
    display: flex;
    gap: .5rem;
}

.session {
    flex: 1;
    border-radius: calc(var(--agenda-radius) * 1);
    overflow: hidden;
}






.sessionHeader {
    width: 100%;
    padding: .5rem 1rem;
    background-color: var(--secondary);
    border-top-left-radius: var(--agenda-radius);
    border-top-right-radius: var(--agenda-radius);
}
.sessionHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: .5rem;
    row-gap: .125rem;
}
.sessionContent {
    padding: 1rem;
    border-bottom-left-radius: var(--agenda-radius);
    border-bottom-right-radius: var(--agenda-radius);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.sessionSpeakers {
    display: flex;
    flex-wrap: wrap;
    gap: var(--speaker-spacing);
}

@media(min-width:600px){
    .sessionContent {
        padding: 1.5rem;
    }
}





.sessionTitle {
    max-width: 50rem;
}

.sessionTagGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .25rem;
}

/* speakerGrid */

.sessionSpeakerGrid {
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--speaker-spacing);
}

@media(min-width:600px) {
    .sessionSpeakerGrid {
        display: inline-flex;
        flex-wrap: wrap;
    }
    .sessionSpeaker {
        width: 12vw;
    }
}

.sessionTag {
    display: inline-block;
    padding: 2px 8px;
    border-radius: calc(var(--agenda-radius) * 2);
    background-color: var(--primary);
    font-size: .8rem;
    color: white;
}
.sessionLocationTag {
    display: inline-block;
}

.test {
    border: 1px solid rgb(255, 0, 208);
}

.speakerTag {
    display: inline-block;
    margin-bottom: .5rem;
    padding: 2px 8px;
    border-radius: calc(var(--agenda-radius) * 2);
    background-color: var(--accent);
    color: black;
    font-size: .8rem;
    font-weight: 500;
    text-transform: uppercase;
}




/* updated layout (columns) */

.sessionsWrapper {
    flex:1;
    display: flex;
    flex-direction: column;
}

.sessionStream {
    padding: .5rem 1rem;
    border-radius: calc(var(--agenda-radius) * 1);
    background-color: var(--primary);
    min-width: 6.25rem;
}

.session {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


@media(max-width:600px) {
    .sessions {
        flex-direction: column;
    }
    .sessionsWrapper {
        position: relative;
        display: flex;
    }

}




/* session anchors */

.sessionsWrapper {
    position: relative;
}

.sessionAnchor {
    position: absolute;
    z-index: 12;
    display: block;

    top: -14rem;
}





/* printer styles --------------------------------------------- */

@media print {

    body {
        background-color: white !important;
    }

    .filters,
    .search,
    .printButton {
        display: none;
    }
    .agenda * {
        color: black !important;
    }


    .agendaWrapper {
        width: 100vw;
    }

    .sessions {
        flex-direction: column;
    }
    .sessionsWrapper {
        break-inside: avoid;
        position: relative;
        flex:1;
        display: flex;
        gap: .5rem;
    }

    .dayDivider {
        background-color: white !important;
        border-top: 2px solid black;
        border-bottom: 2px solid black;
        margin: 2rem 0;
    }

    .sessionHeader {
        border-bottom: 1px solid rgb(200, 200, 200) !important;
        background-color: white !important;
        padding: .5rem 0 .25rem 0;
    }
    .session {
        background-color: white !important;
        border: 1px solid rgb(200, 200, 200);
        color: black !important;
    }
    .sessionContent {
        background-color: white;
        padding: 0;
    }

    .sessionTag {
        color: white !important;
    }
    
    .speakerAvatar {
        display: none;
    }
}


