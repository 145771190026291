.fw-100, .fw-thin {
    font-weight: 100;
}
.fw-200, .fw-extralight {
    font-weight: 200;
}
.fw-300, .fw-light {
    font-weight: 300;
}
.fw-400, .fw-regular {
    font-weight: 400;
}
.fw-500, .fw-medium {
    font-weight: 500;
}
.fw-600, .fw-semibold {
    font-weight: 600;
}
.fw-700, .fw-bold {
    font-weight: 700;
}
.fw-800, .fw-extrabold {
    font-weight: 800;
}
.fw-900, .fw-black {
    font-weight: 900;
}