.sans {
    font-family: sans-serif;
}

.serif {
    font-family: 'AAXHeadline', serif;
}

.mono {
    font-family: monospace;
}


/* mobile size adjuster */
@media(max-width:599px) {
    :root {
        --default-font-size: .85rem;
    }
}

/* tablet size adjuster */
@media(min-width:600px) {
    :root {
        --default-font-size: 1rem;
    }
}

/* medium screen size adjuster */
@media(min-width:1200px) {
    :root {
        --default-font-size: 1rem;
    }
}

/* big screen size adjuster */
@media(min-width:1600px) {
    :root {
        --default-font-size: 1.125rem;
    }
}

/* huge screen size adjuster */
@media(min-width:2000px) {
    :root {
        --default-font-size: 1.25rem;
    }
}

/* chrome book size adjuster */
@media(width:1280px) and (max-height:700px) {
    :root {
        --default-font-size: .75rem;
    }
}

/* ipad portrait size adjuster */
@media (min-width:600px) and (max-width:900px) and (max-height:1200px) and (orientation : portrait) {
    :root {
        --default-font-size: .75rem;
    }
}


/* imports */

