.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5 {

    display: grid;    
    
}


@media (min-width: 600px) {
    .grid-1 {grid-template-columns: repeat(1, 1fr);}
    .grid-2 {grid-template-columns: repeat(2, 1fr);}
    .grid-3 {grid-template-columns: repeat(2, 1fr);}
    .grid-4 {grid-template-columns: repeat(2, 1fr);}
    .grid-5 {grid-template-columns: repeat(2, 1fr);}
}

@media (min-width: 900px) {
    .grid-3 {grid-template-columns: repeat(3, 1fr);}
    .grid-4 {grid-template-columns: repeat(3, 1fr);}
    .grid-5 {grid-template-columns: repeat(4, 1fr);}
}

@media (min-width: 1200px) {
    .grid-4 {grid-template-columns: repeat(4, 1fr);}
}

@media (min-width: 1400px) {
    .grid-5 {grid-template-columns: repeat(5, 1fr);}
}
