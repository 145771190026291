.toggleContainer {
    position: relative;
    z-index: 300;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}
.toggleLabel {
    padding: 0 1.25rem;
    text-align: center;
    font-size: 1rem;
    transition: all .35s ease 400ms;
}

.toggle {
    position: relative;
    cursor: pointer;
    height: 48px;
    width: 48px;

    border-radius: var(--default-button-radius);
    background-color: var(--primary);
    transition: all .35s ease;
}
@media(hover:hover) {
    .toggle:hover {
        background-color: var(--secondary);
    }
}

.toggle span {
    background-color: white;
    border: none;
    height: 2px;
    width: 16px;
    position: absolute;
    top: 24px;
    left: 16px;
    transition: all .35s ease 400ms;
    cursor: pointer;
}
.toggle span:nth-of-type(2) {transform: translateY(6px) rotate(0); }
.toggle span:nth-of-type(3) {transform: translateY(-6px) rotate(0); }

.toggle.active span:nth-of-type(1) {opacity: 0;}
.toggle.active span:nth-of-type(2) {transform: translateY(0) rotate(45deg);}
.toggle.active span:nth-of-type(3) {transform: translateY(0) rotate(-45deg);}

.toggle.active span {
    /* background-color: white; */
    transition: all .35s ease;
}

/* .toggle.active + .toggleLabel {
    color: white;
    transition: all .35s ease;
} */

@media(max-width:599px){
    .toggleContainer {
        position: fixed;
        top: var(--unit-xs);
        right: var(--space-md);

    }
    .toggleLabel {
        display: none;
    }
    /* .toggle {
        background-color: var(--secondary);
    }
    .toggle span {
        background-color: white;
    } */
}



/* overlay */

.overlay {
    z-index: 0;
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--primary);
    color: var(--secondary);

    padding: var(--unit-sm);
    padding-top: var(--space-md);

    pointer-events: none;
    transition: all 500ms cubic-bezier(.7, .1, .2, 1);

    overflow-y: scroll;
}
.overlay.active {
    top: 0;
    pointer-events: all;
}
.overlay ul {
    list-style: none;
    margin-bottom: .5rem;
}
.overlay ul li {
    margin-bottom: .5rem;
}

@media(min-width:600px) {
    .overlay {
        padding: calc(var(--header-height) * 1) var(--unit-lg) var(--unit-lg);
    }
    .overlay ul {
        list-style: none;
        margin-bottom: .5rem;
    }
    .overlay ul li {
        margin-bottom: .5rem;
    }
    .toggleContainer {      
        /* border-left: 1px solid white; */
    }
}


@media(min-width:600px) {
    .overlay {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
    }
    
    .actions {
        align-self: stretch;
        border-left: 1px solid var(--dark);
        padding-left: var(--space-md);
    }
}