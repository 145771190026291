/* 
    --container-max-width
    --container-adjacent-spacing-factor
*/

.container {
    max-width: var(--container-max-width, 90rem);
    margin: 0 auto;
}

.container + .container {
    margin-top: calc(var(--unit-sm, 2rem) * var(--container-adjacent-spacing-factor, 0.65));
}


@media (min-width: 600px) {
    .container + .container { margin-top: calc(var(--unit-sm, 2rem) * var(--container-adjacent-spacing-factor, 0.65)); }
}
@media (min-width: 900px) {
    .container + .container { margin-top: calc(var(--unit-md, 4rem) * var(--container-adjacent-spacing-factor, 0.65)); }
}
@media (min-width: 1200px) {
    .container + .container { margin-top: calc(var(--unit-lg, 6rem) * var(--container-adjacent-spacing-factor, 0.65)); }
}
@media (min-width: 1800px) {
    .container + .container { margin-top: calc(var(--unit-xl, 8rem) * var(--container-adjacent-spacing-factor, 0.65)); }
}