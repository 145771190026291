/* global reset */

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
body{
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: inherit;
}

ul, ol {
  list-style: none;
}

/* end global reset */


