.minw-10pc { min-width: 10%; }
.minw-20pc { min-width: 20%; }
.minw-30pc { min-width: 30%; }
.minw-40pc { min-width: 40%; }
.minw-50pc { min-width: 50%; }
.minw-60pc { min-width: 60%; }
.minw-70pc { min-width: 70%; }
.minw-80pc { min-width: 80%; }
.minw-90pc { min-width: 90%; }


.minw-10 { min-width: 10rem; }
.minw-20 { min-width: 20rem; }
.minw-30 { min-width: 30rem; }
.minw-40 { min-width: 40rem; }
.minw-50 { min-width: 50rem; }
.minw-60 { min-width: 60rem; }
.minw-70 { min-width: 70rem; }
.minw-80 { min-width: 80rem; }
.minw-90 { min-width: 90rem; }