
.form > div {
    display: block;
    position: relative;
    margin-bottom: var(--space-xs);
}

.form label {
    display: block;
    margin-bottom: .5rem;
    font-weight: 400;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='tel'],
.form textarea {
    outline: none;
    font-family: var(--default-font-family, inherit);
    font-size: var(--default-font-size, inherit);
    width: 100%;
    padding: .75rem 1rem;
    border: 1px var(--standard-border-color, rgb(230, 230, 230)) solid;
    border-radius: var(--standard-field-radius, 0);
    background-color: var(--standard-field-bg, rgb(247, 247, 247));
    transition: border 350ms ease;
}



.form input[type='submit'] {
	cursor: pointer;
	display: inline-block;
	font-family: var(--default-font-family, inherit);
    font-size: var(--default-font-size, inherit);
    font-weight: 600;
	padding: .75rem 2rem;
	border: 1px solid var(--primary);
    border-radius: 4rem;
	background-color: var(--primary);
	color: white;
	transition: all 500ms ease-in-out;
}

.form input[type='submit']:hover {
	transition: all 225ms ease-in-out;
    border: 1px solid black;
	background-color: black;
}





/* select */

.form select {
    cursor: pointer;
    appearance:none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    width: 100%;
    padding: .75rem 1rem;
    border: 1px var(--standard-border-color, rgb(230, 230, 230)) solid;
    border-radius: var(--standard-field-radius, 0);
    background-color: var(--standard-field-bg, rgb(247, 247, 247));

    /* background-image: url('/assets/ui/dropdown-chevron.svg'); */
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem);
}
.form select:focus {
    outline: none;
    border-color: inherit;
}
.form select:invalid {
    border-color: var(--error, rgb(255, 68, 0));
    color: var(--error, rgb(255, 68, 0));
}
.form select:required {
    border-color: red;
    color: red;
}




/* validation */

.form input[type='submit']:focus,
.form input[type='text']:focus,
.form input[type='email']:focus,
.form input[type='password']:focus,
.form input[type='tel']:focus,
.form textarea:focus {
    border-color: inherit;
}
/* .form input[type='text']:invalid,
.form input[type='email']:invalid,
.form input[type='password']:invalid {
    border-color: var(--error, rgb(255, ÷68, 0));
}
.form input:invalid {
    color: var(--error, rgb(255, 68, 0));
} */

.form input[type='checkbox'] {
    margin-right: .5rem;
}



/* HUBSPOT SPECIFIC */

.hs-form-field {
    display: block;
    margin-bottom: .75rem;
}

.hs-form-field textarea,
.hs-form-field input[type='text'],
.hs-form-field input[type='email'] {
    width: 100% !important;
}

.hs_submit, .hs-submit {
    margin-top: var(--space-sm);
}

.hs-error-msgs {
    list-style: none;
    color: var(--error);
    font-weight: 400;
}

.hs-error-msg {
    margin-top: .5rem;
    color: var(--error);
    font-weight: 400;
}

.form-columns-2 {
    max-width: 100% !important;
}

.hs-richtext {
    display: none !important;
}