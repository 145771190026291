.button {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    padding: .6rem;

    transition: all 250ms ease-in-out;
}
.button:hover {
    transform: scale(1.1);
}

.icon {
    display: block;
    max-width: 2rem;
    max-height: 2rem;
    fill: white;
}