.h, .h1, .h2, .h3, .h4, .h5, .h6,
 h1, h2, h3, h4, h5, h6 {
    margin-bottom: calc(var(--default-line-height, 1.2) * 0.75rem);
    font-weight: var(--default-heading-weight, 600);
    line-height: calc(var(--default-line-height, 1.4) * 0.9);
}

p + .h, p + .h1, p + .h2, p + .h3, p + .h4, p + .h5, p + .h6,
p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    margin-top: calc(var(--default-line-height, 1.5) * 1rem);
}