.b-1, .b-xs { border: 1px solid currentColor; }
.b-2, .b-sm { border: 2px solid currentColor; }
.b-3  { border: 3px solid currentColor; }
.b-4, .b-md { border: 4px solid currentColor; }
.b-5 { border: 5px solid currentColor; }
.b-6, .b-lg { border: 6px solid currentColor; }
.b-7 { border: 7px solid currentColor; }
.b-8, .b-xl { border: 8px solid currentColor; }
.b-9 { border: 9px solid currentColor; }
.b-10 { border: 10px solid currentColor; }

.bt-1, .bt-xs { border-top: 1px solid currentColor; }
.bt-2, .bt-sm { border-top: 2px solid currentColor; }
.bt-3 { border-top: 3px solid currentColor; }
.bt-4, .bt-md { border-top: 4px solid currentColor; }
.bt-5 { border-top: 5px solid currentColor; }
.bt-6, .bt-lg { border-top: 6px solid currentColor; }
.bt-7 { border-top: 7px solid currentColor; }
.bt-8, .bt-xl { border-top: 8px solid currentColor; }
.bt-9 { border-top: 9px solid currentColor; }
.bt-10 { border-top: 10px solid currentColor; }

.bb-1, .bb-xs { border-bottom: 1px solid currentColor; }
.bb-2, .bb-sm { border-bottom: 2px solid currentColor; }
.bb-3 { border-bottom: 3px solid currentColor; }
.bb-4, .bb-md { border-bottom: 4px solid currentColor; }
.bb-5 { border-bottom: 5px solid currentColor; }
.bb-6, .bb-lg { border-bottom: 6px solid currentColor; }
.bb-7 { border-bottom: 7px solid currentColor; }
.bb-8, .bb-xl { border-bottom: 8px solid currentColor; }
.bb-9 { border-bottom: 9px solid currentColor; }
.bb-10 { border-bottom: 10px solid currentColor; }

.bl-1, .bl-xs { border-left: 1px solid currentColor; }
.bl-2, .bl-sm { border-left: 2px solid currentColor; }
.bl-3 { border-left: 3px solid currentColor; }
.bl-4, .bl-md { border-left: 4px solid currentColor; }
.bl-5 { border-left: 5px solid currentColor; }
.bl-6, .bl-lg { border-left: 6px solid currentColor; }
.bl-7 { border-left: 7px solid currentColor; }
.bl-8, .bl-xl { border-left: 8px solid currentColor; }
.bl-9 { border-left: 9px solid currentColor; }
.bl-10 { border-left: 10px solid currentColor; }

.br-1, .br-xs { border-right: 1px solid currentColor; }
.br-2, .br-sm { border-right: 2px solid currentColor; }
.br-3 { border-right: 3px solid currentColor; }
.br-4, .br-md { border-right: 4px solid currentColor; }
.br-5 { border-right: 5px solid currentColor; }
.br-6, .br-lg { border-right: 6px solid currentColor; }
.br-7 { border-right: 7px solid currentColor; }
.br-8, .br-xl { border-right: 8px solid currentColor; }
.br-9 { border-right: 9px solid currentColor; }
.br-10 { border-right: 10px solid currentColor; }