.w-10pc { width: 10%; }
.w-20pc { width: 20%; }
.w-30pc { width: 30%; }
.w-40pc { width: 40%; }
.w-50pc { width: 50%; }
.w-60pc { width: 60%; }
.w-70pc { width: 70%; }
.w-80pc { width: 80%; }
.w-90pc { width: 90%; }
.w-100pc { width: 100%; }


.w-10 { width: 10rem; }
.w-20 { width: 20rem; }
.w-30 { width: 30rem; }
.w-40 { width: 40rem; }
.w-50 { width: 50rem; }
.w-60 { width: 60rem; }
.w-70 { width: 70rem; }
.w-80 { width: 80rem; }
.w-90 { width: 90rem; }
.w-100 { width: 100rem; }