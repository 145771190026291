.maxw-10pc { max-width: 10%; }
.maxw-20pc { max-width: 20%; }
.maxw-30pc { max-width: 30%; }
.maxw-40pc { max-width: 40%; }
.maxw-50pc { max-width: 50%; }
.maxw-60pc { max-width: 60%; }
.maxw-70pc { max-width: 70%; }
.maxw-80pc { max-width: 80%; }
.maxw-90pc { max-width: 90%; }


.maxw-10 { max-width: 10rem; }
.maxw-15 { max-width: 15rem; }
.maxw-20 { max-width: 20rem; }
.maxw-25 { max-width: 25rem; }
.maxw-30 { max-width: 30rem; }
.maxw-35 { max-width: 35rem; }
.maxw-40 { max-width: 40rem; }
.maxw-45 { max-width: 45rem; }
.maxw-50 { max-width: 50rem; }
.maxw-55 { max-width: 55rem; }
.maxw-60 { max-width: 60rem; }
.maxw-65 { max-width: 65rem; }
.maxw-70 { max-width: 70rem; }
.maxw-75 { max-width: 75rem; }
.maxw-80 { max-width: 80rem; }
.maxw-85 { max-width: 85rem; }
.maxw-90 { max-width: 90rem; }
.maxw-95 { max-width: 95rem; }