html {
    background-color: var(--global-bg, white);
    color: var(--default-text-color, rgb(40, 40, 40));
}

body {
    font-family: var(--default-font-family, sans-serif);
    font-weight: var(--default-font-weight, 400);
}

h1, h2, h3, h4, h5, h6 { 
    overflow-wrap: break-word;
    word-wrap: break-word;
}

a {color: currentColor;}

.relative { position: relative; }

.overflow-h { overflow: hidden; }

.inline { display: inline-block; }

.overscroll {
    overscroll-behavior: contain;
}

@media(max-width:600px){
    .hyphenate {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
}

.image {
    display: block;
}

.print-only {
    display: none;
}
.no-print {
    display: block
}

@media print {
    .print-only {
        display: block;
    }
    .no-print,
    .no-print * {
        display: none;
    }
}