:root {

    /* colour */

    /* --primary: #FF5A02; */
    --primary: #FF5A02;
    --secondary: #262626;
    --accent: #96B71D;

    --sand: #FE7C02;

    --bg-default: #F8F6F2;
    --bg-theme: var(--primary);

    --default-text-color: rgb(71, 71, 71);
    --default-heading-color: var(--primary);

    --wash: #F8F6F2;
    --light: #EEEBE5;
    --medium: #FF5A02;
    --dark: #262626;
    --extra-dark: #262626;

    --error: #EC662C;
    --success: #96B71D;
    --warning: #FFA200;


    /* spacing */

    --container-max-width: 200rem;
    --container-adjacent-spacing-factor: 0.5;

    --unit-xxs: .5rem;
    --unit-xs: 1rem;
    --unit-sm: 2rem;
    --unit-md: 4rem;
    --unit-lg: 6rem;
    --unit-xl: 8rem;


    /* type */

    --default-font-family: 'Outfit', sans-serif;
    --default-font-weight: 300;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* global UI styling */

    --default-radius: 0;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 4rem;

    --gap-divider-color: currentColor;

    /* header */

    --header-height: 6.875rem;
    --header-height-mobile: 9rem;


    /* FORMS ---------------------------- */

    --standard-field-radius: calc(var(--default-radius) * 0.6);

}