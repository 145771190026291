.layout {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    /* margin-top: -20vw; */

    color: white;
}
.topGraphic {
    grid-area: 1/1 / 2/2;
    position: relative;
    z-index: 2;
    width: 100%;
    aspect-ratio: 1680/450;
}
.content {
    grid-area: 2/1 / 3/2;
    z-index: 2;
    padding-top: var(--space-sm);
}







.hosts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}


.partnerLogo,
.partnerLogoLarge {
    display: block;
    margin: 0 auto;
}
.partnerLogo {
    max-width: 10rem;
    max-height: 9rem;
}
.partnerLogoLarge {
    max-width: 14rem;
    max-height: 10rem;
}

@media(max-width:799px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 35rem;
    }
    .section {
        padding: var(--space-md) 0;
    }
    .divider {
        border-top: 1px solid var(--secondary);
    }
}

@media(min-width:800px) {
    .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    
    .divider {
        border-left: 1px solid var(--primary);
    }
    .section {
        padding: 0 var(--space-md);
    }
    .section:nth-child(1) {
        padding-left: 0;
    }
    .section:nth-child(3) {
        padding-right: 0;
    }
    
}

.topGraphic {
    grid-area: 1/1 / 2/2;
    position: relative;
    z-index: 2;
    width: 100%;
    aspect-ratio: 1680/285;
}



@media print {
    .layout {
        display: none;
    }
}